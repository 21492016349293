<template>
  <div data-vuetify v-show="available" id="appBannerWrapper">
    <v-app>
      <v-banner single-line height="58.8" :style="isMobile ? { zIndex: isLandscape() || asMobileLandscape() || asTabletOrIpadsPortrait() ? 1 : 4000, position: isLandscape() || asMobileLandscape() || asTabletOrIpadsPortrait() ? 'static' : 'fixed', backgroundColor: 'white', width: '100vw' } : { zIndex: 2 }">
        <v-avatar slot="icon" size="40">
          <img src="/img/icons/apple-touch-icon-76x76.png">
        </v-avatar>

        <div style="font-size: 0.75rem;" class="font-weight-bold">ウェブザベ!</div>
        <div style="font-size: 0.625rem;" class="grey--text">アプリをインストールする</div>

        <template v-slot:actions="{ dismiss }">
          <v-btn small depressed color="primary" @click="install">
            インストール
          </v-btn>
          <v-btn small icon @click="dismiss(); available = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-banner>
    </v-app>

    <vue-final-modal v-model="showIosInstallModal" classes="modal-container" content-class="modal-content" :click-to-close="false " z-index="9999">
      <div class="modal__content">
        ブラウザの<v-icon color="rgba(0, 0, 0, 0.87)" small style="vertical-align: baseline;">mdi-export-variant</v-icon>（共有ボタン）をタップして「ホームに追加」を選択してください。<br>
        これでウェブザベ!がホーム画面に追加されます。
        ホーム画面から起動すると、アプリのようにフルスクリーンで使うことができます。
      </div>
      <div class="modal__action">
        <v-btn @click="closeIosInstallModal">わかりました</v-btn>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { isIOS, isMobile } from 'mobile-device-detect'
import { asMobileLandscape, asTabletOrIpadsPortrait, isLandscape, isPortrait, isStandalone } from '../utils'

export default {
  data () {
    return {
      promptEvent: null,
      available: false,
      showIosInstallModal: false
    }
  },
  watch: {
    available (newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        const header = document.getElementsByTagName('header')[0]

        if (header !== undefined) {
          if (isPortrait() && !asMobileLandscape() && !asTabletOrIpadsPortrait()) {
            header.style.top = '0'
          }
        }
        const dialogHeader = document.getElementsByClassName('dialog__header')[0]
        if (dialogHeader !== undefined) {
          dialogHeader.style.paddingTop = '10px'
        }
        if (isMobile) {
          const baseGrid = document.querySelector('header + div.grid')
          const problemSearch = document.querySelector('.problem-search')
          if (baseGrid !== null) {
            if (isPortrait() && !asMobileLandscape() && !asTabletOrIpadsPortrait()) {
              if (problemSearch !== null) {
                baseGrid.style.paddingTop = '110.4px'
              } else {
                baseGrid.style.paddingTop = '76.8px'
              }
            }
          }
        }
        this.$appBannerAvailable = false
      }
    }
  },
  computed: {
    isMobile () {
      return isMobile
    }
  },
  methods: {
    isLandscape () {
      return isLandscape()
    },
    asMobileLandscape () {
      return asMobileLandscape()
    },
    asTabletOrIpadsPortrait () {
      return asTabletOrIpadsPortrait()
    },
    install (e) {
      console.log('install')
      if (this.promptEvent) {
        this.promptEvent.prompt()
        this.promptEvent.userChoice
          .then((choiceResult) => {
            this.promptEvent = null
            this.available = false
          })
          .catch((installError) => {
            this.promptEvent = null
            this.available = false
          })
      } else if (isIOS) {
        this.showIosInstallModal = true
      }
    },
    installed () {
      console.log('installed')
      this.promptEvent = null
      this.available = false
    },
    beforeInstallPrompt (e) {
      console.log('beforeinstallprompt')
      this.promptEvent = e
      this.promptEvent.preventDefault()
      this.available = true
      this.$appBannerAvailable = true
      return false
    },
    openIosInstallModal () {
      this.showIosInstallModal = true
    },
    closeIosInstallModal () {
      this.showIosInstallModal = false
      this.available = false
    }
  },
  created () {
    window.addEventListener('beforeinstallprompt', this.beforeInstallPrompt)
    window.addEventListener('appinstalled', this.installed)
  },
  mounted () {
    if (isMobile && !isStandalone()) {
      this.available = true
      this.$appBannerAvailable = true

      // TODO: 汚すぎる
      window.setTimeout(() => {
        const header = document.getElementsByTagName('header')[0]
        const dialogHeader = document.getElementsByClassName('dialog__header')[0]

        if (header !== undefined) {
          console.log('header')
          header.style.top = '58.8px'
        }
        if (dialogHeader !== undefined) {
          console.log('dialogHeader')
          dialogHeader.style.paddingTop = 'calc(10px + 58.8px)'
        }
      }, 500)
    }
  }
}
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}
.modal__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 40px;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1rem 0 0;
}
.modal__content {
  padding: 1rem 0 0;
}
.modal__action {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem 0 0;
}

.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}

::v-deep .v-banner__actions {
  margin-left: 0 !important;
}
</style>
