import { isMobile } from 'mobile-device-detect'

const isLandscape = () => {
  const direction = Math.abs(window.orientation)
  return direction === 90
}

const isPortrait = () => {
  const direction = Math.abs(window.orientation)
  return direction !== 90
}

const isStandalone = () => {
  return window.matchMedia('(display-mode: standalone)').matches
}

// 主にSurface Duo用
const asMobileLandscape = () => {
  const windowWidth = window.innerWidth
  return isMobile && windowWidth > 480 && windowWidth < 768
}

// 主にiPad Mini用
const asTabletOrIpadsPortrait = () => {
  const windowWidth = window.innerWidth
  return isMobile && windowWidth > 767
}

export {
  isLandscape,
  isPortrait,
  isStandalone,
  asMobileLandscape,
  asTabletOrIpadsPortrait
}
